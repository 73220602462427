import { PUB_SUB_EVENTS } from '../helper/constants';
import { fetchConfig } from '../helper/fetch-config';
import { publish } from '../helper/pubsub';

/**
 * Product Form Component
 *
 * Handles form submission, cart updates, and error handling for product forms.
 * Supports both v2 and v3 product architecture.
 *
 * @fires cartUpdate - When a product is successfully added to cart
 * @fires cartError - When there's an error adding a product to cart
 */

if (!customElements.get('product-form')) {
  customElements.define(
    'product-form',
    class ProductForm extends HTMLElement {
      constructor() {
        super();

        this.hideErrors = this.dataset.hideErrors === 'true';
        this.promo_message = '';
        this.tag_array = [];
        this.error = false;

        // Cache selectors that will be repeatedly accessed
        this.cachedElements = {};
      }

      connectedCallback() {
        // Initialize cached elements
        this.cachedElements = {
          form: this.querySelector('form'),
          cart: document.querySelector('cart-drawer'),
          submitButton: this.querySelector('[type="submit"]'),
          promos: document.querySelectorAll('.js-promotext'),
          productTags: this.querySelectorAll('.product-tag'),
          promoElem: this.querySelector('.promo-text'),
          sizeSelect: document.querySelector('variant-radios'),
          stickyATC: document.querySelector('sticky-atc'),
          message: document.querySelector('.js-error-message'),
          errorMessageWrapper: this.querySelector('.product-form__error-message-wrapper'),
          errorMessage: this.querySelector('.product-form__error-message')
        };

        // Set up form submission
        if (this.cachedElements.form) {
          const idInput = this.cachedElements.form.querySelector('[name=id]');
          if (idInput) {
            idInput.disabled = false;
          }
          this.cachedElements.form.addEventListener('submit', this.onSubmitHandler.bind(this));
        }

        // Process product tags for promotions
        this.initializePromotions();
      }

      /**
       * Initialize product promotions based on tags
       */
      initializePromotions() {
        // Build tag array from product tags
        if (this.cachedElements.productTags) {
          this.cachedElements.productTags.forEach((elem) => {
            const tagValue = elem.dataset.tag;
            if (tagValue) {
              this.tag_array.push(tagValue);
            }
          });
        }

        // Apply matching promo text
        if (this.cachedElements.promos && this.cachedElements.promoElem) {
          this.cachedElements.promos.forEach((elem) => {
            if (this.tag_array.includes(elem.dataset.tag)) {
              if (elem.dataset.tag != '3 for $48 panties' && window.location.href.indexOf('products') != -1) {
                this.cachedElements.promoElem.textContent = elem.textContent;
                this.cachedElements.promoElem.style.color = `${elem.dataset.color}`;
              }
            }
          });
        }
      }

      onSubmitHandler(evt) {
        evt.preventDefault();

        const { submitButton, sizeSelect, message, stickyATC } = this.cachedElements;

        if (submitButton.getAttribute('aria-disabled') === 'true') return;

        // Size validation for v3 products
        if (submitButton.getAttribute('select-size') === 'true') {
          // Skip validation if we don't have size selection
          if (!sizeSelect) {
            submitButton.setAttribute('select-size', 'false');
            return;
          }

          // Check if there is a size selected
          const radios = sizeSelect.querySelectorAll('input[type="radio"]');
          const hasValue = Array.from(radios).some(element => element.checked);

          if (hasValue) {
            // Size is selected, proceed
            if (message) message.textContent = '';
            submitButton.setAttribute('select-size', 'false');
            if (stickyATC) stickyATC.removeAttribute('scoll');
          } else {
            // No size selected, show error
            if (message) message.textContent = window.variantStrings.selectVariant;
            submitButton.setAttribute('select-size', 'true');
            if (stickyATC) stickyATC.setAttribute('scroll', 'true');
          }
          return;
        }

        this.handleErrorMessage();
        this.submitFormToCart();
      }

      /**
       * Submit form data to cart API
       */
      submitFormToCart() {
        const { form, submitButton, cart } = this.cachedElements;

        submitButton.setAttribute('aria-disabled', true);
        submitButton.classList.add('loading');

        const config = fetchConfig('javascript');
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        delete config.headers['Content-Type'];

        const formData = new FormData(form);

        // Prepare cart sections if cart drawer exists
        if (cart) {
          formData.append(
            'sections',
            cart.getSectionsToRender().map((section) => section.id)
          );
          formData.append('sections_url', window.location.pathname);
          cart.setActiveElement(document.activeElement);
        }

        config.body = formData;

        fetch(`${routes.cart_add_url}`, config)
          .then((response) => response.json())
          .then((response) => this.handleCartResponse(response, formData))
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            submitButton.classList.remove('loading');
            const { cart } = this.cachedElements;
            if (cart && cart.classList.contains('is-empty')) cart.classList.remove('is-empty');
            if (!this.error) submitButton.removeAttribute('aria-disabled');
          });
      }

      /**
       * Handle cart API response
       * @param {Object} response - Cart API response
       * @param {FormData} formData - Form data that was submitted
       */
      handleCartResponse(response, formData) {
        const { cart, submitButton } = this.cachedElements;

        // Handle error response
        if (response.status) {
          publish(PUB_SUB_EVENTS.cartError, {
            source: 'product-form',
            productVariantId: formData.get('id'),
            errors: response.description,
            message: response.message
          });

          this.handleErrorMessage(response.description);

          const soldOutMessage = submitButton.querySelector('.sold-out-message');
          if (soldOutMessage) {
            submitButton.setAttribute('aria-disabled', true);
            submitButton.querySelector('span').classList.add('hidden');
            soldOutMessage.classList.remove('hidden');
          }

          this.error = true;
          return;
        }

        // Redirect to cart if no cart drawer
        if (!cart) {
          window.location = window.routes.cart_url;
          return;
        }

        // Success - publish cart update event
        if (!this.error) {
          publish(PUB_SUB_EVENTS.cartUpdate, {
            source: 'product-form',
            productVariantId: formData.get('id')
          });
        }

        this.error = false;
        cart.trigger(cart);
        cart.renderContents(response);
      }

      handleErrorMessage(errorMessage = false) {
        if (this.hideErrors) return;

        // Lazy initialize error elements if not already in cachedElements
        const wrapper = this.cachedElements.errorMessageWrapper ||
          this.querySelector('.product-form__error-message-wrapper');
        const message = this.cachedElements.errorMessage ||
          (wrapper && wrapper.querySelector('.product-form__error-message'));

        if (!wrapper) return;

        // Cache for future calls
        this.cachedElements.errorMessageWrapper = wrapper;
        this.cachedElements.errorMessage = message;

        wrapper.toggleAttribute('hidden', !errorMessage);

        if (errorMessage && message) {
          message.textContent = errorMessage;
        }
      }
    }
  );
}
